document.addEventListener('DOMContentLoaded', function () {
  let canvas = document.getElementById('canvas');
  const ctx = canvas.getContext('2d');
  const img = new Image();
  img.src = "https://imagedelivery.net/f0LVWjJuX1POkXeHzYITaQ/649b7e87-d751-4a70-7127-89ad2f452d00/public";

  // general settings
  let gamePlaying = false;
  const gravity = .4;
  let speed = 2;
  const size = [51, 36];
  const jump = -11.5;
  const cTenth = (canvas.width / 10);

  let index = 0;
  let bestScore = 0;
  let flight;
  let flyHeight;
  let currentScore;
  let pipe;

  // pipe settings
  const pipeWidth = 78;
  const pipeGap = 270;
  const pipeLoc = () => (Math.random() * ((canvas.height - (pipeGap + pipeWidth)) - pipeWidth)) + pipeWidth;

  const setup = () => {
    currentScore = 0;
    flight = jump;
    gamePlaying = false;
    speed = 2;

    // set initial flyHeight (middle of screen - size of the bird)
    flyHeight = (canvas.height / 2) - (size[1] / 2);

    // setup first 3 pipes
    pipes = Array(3).fill().map((a, i) => [canvas.width + (i * (pipeGap + pipeWidth)), pipeLoc()]);
  };

  const render = () => {
    // make the pipe and bird moving
    index++;

    // background first part
    ctx.drawImage(img, 0, 0, canvas.width, canvas.height, -((index * (speed / 2)) % canvas.width) + canvas.width, 0, canvas.width, canvas.height);
    // background second part
    ctx.drawImage(img, 0, 0, canvas.width, canvas.height, -(index * (speed / 2)) % canvas.width, 0, canvas.width, canvas.height);

    // pipe display
    if (gamePlaying) {
      pipes.map((pipe) => {
        // pipe moving
        pipe[0] -= speed;

        // top pipe
        ctx.drawImage(img, 432, 588 - pipe[1], pipeWidth, pipe[1], pipe[0], 0, pipeWidth, pipe[1]);
        // bottom pipe
        ctx.drawImage(img, 432 + pipeWidth, 108, pipeWidth, canvas.height - pipe[1] + pipeGap, pipe[0], pipe[1] + pipeGap, pipeWidth, canvas.height - pipe[1] + pipeGap);

        // give 1 point & create new pipe
        if (pipe[0] <= -pipeWidth) {
          currentScore++;
          speed+= 0.2;
          // check if it's the best score
          bestScore = Math.max(bestScore, currentScore);

          // remove & create new pipe
          pipes = [...pipes.slice(1), [pipes[pipes.length-1][0] + pipeGap + pipeWidth, pipeLoc()]];
          // console.log(pipes);
        }
        // if hit the ground, end
        if(flyHeight >= 564){
          document.dispatchEvent(new CustomEvent('game-over', {detail: {score: currentScore}}));
          setup();
        }
        // if hit the pipe, end
        if ([
          pipe[0] <= cTenth + size[0],
          pipe[0] + pipeWidth >= cTenth,
          pipe[1] > flyHeight || pipe[1] + pipeGap < flyHeight + size[1]
        ].every((elem) => elem)) {
          document.dispatchEvent(new CustomEvent('game-over', {detail: {score: currentScore}}));
          setup();
        }
      });
    }
    // draw bird
    if (gamePlaying) {
      ctx.drawImage(img, 432, Math.floor((index % 9) / 3) * size[1], ...size, cTenth, flyHeight, ...size);
      flight += gravity;
      flyHeight = Math.min(flyHeight + flight, canvas.height - size[1]);
    } else {
      ctx.drawImage(img, 432, Math.floor((index % 9) / 3) * size[1], ...size, ((canvas.width / 2) - size[0] / 2), flyHeight, ...size);
      flyHeight = (canvas.height / 2) - (size[1] / 2);
        // text accueil
      ctx.fillText(`Tu mejor puntaje : ${bestScore}`, 35, 245);
      ctx.fillText('Aletea para empezar', 45, 535);
      ctx.font = 'bold 30px courier';
    }

    document.getElementById('bestScore').innerHTML = `Tu mejor : ${bestScore}`;
    document.getElementById('currentScore').innerHTML = `Actual : ${currentScore}`;

    // tell the browser to perform anim
    window.requestAnimationFrame(render);
  };

  // launch setup
  setup();
  img.onload = render;

  // start game
  document.addEventListener('flap', () => {
    const nick = document.getElementById("nickname").value
    const email = document.getElementById("email").value
    if(nick == '') {
      document.getElementById("nickname").focus()
    } else if(email == '') {
      document.getElementById("email").focus()
    } else {
      document.dispatchEvent(new Event('game-start'));
      gamePlaying = true;
      timeStart = new Date;
    }
  });
  document.addEventListener('flap', () => {
    if (gamePlaying) flight = jump * 0.75;
  });
}, false);
